.border_radius_10 {
    border-radius: 10px;
}
.height_160 {
    height: 160px;
}
.video_container {
    max-height: 160px;
}
.iframe_container {
    max-height: 160px;
    width: 320px;
    padding: 5px;
}
.z_index {
    z-index: 900;
} 

.height_160 {
    height: 160px;
}
.animation {
   transform: scale(1.02);
   border-radius: 0 !important;
   transition: all 0.5s;
   cursor: pointer;
   padding: 0;
   border-radius: 10px;
}

.video_animation {
    transition: all 0.5s;
    cursor: pointer;
    /* padding: 0; */
    border-radius: 20px; 
    transform: scale(1.00);
}

.video_container:hover {
    cursor: pointer;
    border-radius: 10px;
}

.border_radus_10 {
    border-radius: 10px;
    max-width: 310px;
    padding: 9px 7px 0 7px;
    /* padding: 9px 9px 0px 9px; */
}

@media screen and (max-width:1021px) {
    .iframe_container {
        width: 300px;
    }
 }
 @media screen and (max-width:980px) {
    .iframe_container {
        width: 300px;
    }
 }
 @media screen and (max-width:981px) {
    .iframe_container {
        width: 320px;
    }
 }
 @media screen and (max-width:735px) {
    .iframe_container {
        width: 300px;
    }
 }

 @media screen and (max-width:701px) {
    .iframe_container {
        width: 300px;
    }
 }
 @media screen and (max-width:650px) {
    .iframe_container {
        width: 280px;
    }
 }
 @media screen and (max-width:610px) {
    .iframe_container {
        width: 360px;
    }
    .cartoonsItemContainer {
        width: 360px;
    }
 }

 @media screen and (max-width:400px) {
    .iframe_container {
        width: 300px;
    }
 }

 @media screen and (max-width:340px) {
    .iframe_container {
        width: 280px;
    }
 }

 @media screen and (max-width:310px) {
    .iframe_container {
        width: 260px;
    }
 }