.gap20 {
    gap: 20px;
}

.singleProductPageContainer {
    gap: var(--gap_xl);
    min-width: 120px;
}

.addToList {
    width: 250px !important;
}

.spaceComponents {
    gap: var(--gap_2xl)
}

.selectedBookDataContainer {
    background: var(--mainColorBackground);
}

.selectedBookData {
    border: 1px solid var(--black);
    background: var(--white);
    max-width: 800px;
    height: 460px;
    border-radius: var(--xl);
    gap: 35px;
}

.borderBottom {
    border-bottom: 1px solid var(--black);
    border-radius: 0px 12px 0px 0px;
}

.padding {
    padding: 0 20px 0 0;
}

.actions_for_book {
    background: var(--mainColor);
    color: var(--mainGoogleColor);
    gap: 10px;
    height: 50px;
    padding: 20px;
    border-radius: 10px;
    max-width: 230px;
    min-width: 175px;
    font-size: var(--smFontSize);
    font-weight: var(--fontWeight_400);
}

.actions_for_book img {
    width: 20px;
}

.add_to_list {
    min-width: 200px
}

.description {
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
}

.read_more_container {
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-overflow: ellipsis;
}

.read_more_container, .description:hover {
    cursor: pointer;
}
@media screen and (max-width:905px) {
    .actions_for_book {
        min-width: 150px;
    }
}

@media screen and (max-width:830px) {
    .actions_for_book {
        min-width: 140px;
    }
}

@media screen and (max-width:820px) {
    .selectedBookData {
        position: relative;
        flex-wrap: wrap;
    }

    .singleProductPageContainer {
        text-align: center;
        position: relative;
    }

    .selectedBookData {
        height: auto;
        position: none;
    }

    .padding {
        padding: 0 20px;
    }
}

@media screen and (max-width:500px) {
    .singleProductPageContainer span {
        font-size: 16px;
    }

    .authorName {
        font-size: 14px !important
    }
}

@media screen and (max-width:345px) {
    .gap50 {
        width: 100%;
    }
}

@media screen and (max-width:370px) {
    .flexWrap {
        flex-wrap: wrap;
    }
}