.margin_right_10 {
    margin-right: 10px;
  }
  .gap_3 {
    gap: 3px;
  }

  .play_back {
  width: 17px;
  height: 17px;
  margin-right: 5px;
}

.normal_screen {
  width: 20px;
  height: 20px;
}

.player_icon {
  width: 20px;
  height: 20px;
}
  
.cursor_pointer {
  cursor: pointer;
}

.fill_white {
  filter: brightness(0) invert(1);
}

.w-98 {
  width: 99.8%;
}

.hide {
  opacity: 0;
  transition: visibility 1.5s, opacity 1.5s linear;
}

.player_bg {
  background-color: rgba(0, 0, 0, 0.25);
  width: 100.2%;
  padding: 0 1.5%;
  padding-top: 3px;
  border-radius: 0 0 8px 8px;
}

.py_3 {
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
}

.padding_right_20 {
  padding-right: 20px;
}

.margin_bottom_3 {
  margin-bottom: 3px !important;
}

.height_25 {
  height: 25px !important;
}

.white {
  color: var(--white);
}

.time_width {
  /* width: 40px; */
  max-width: 60px;
}

.width_20 {
  width: 20px;
}

.menu img{
  filter: brightness(0) invert(1);
}

.controll_menu {
  margin-left: 4px;
}

.time > p {
  display: flex;
  align-items: center;
  /* margin-top: 2px; */
  font-family: sans-serif !important;
}

.hour {
  min-width: 0px;
  width: 0;
}

.min {
  max-width: 14px;
}

.duration_time > p {
  display: flex;
  align-items: center;
  margin-top: 2px !important;
  font-family: sans-serif !important;
}
.font_sans_serif {
  font-family: sans-serif !important
}

@media screen and (min-width: 887px) {
  .controll_menu {
    margin-left: 0px;
  }
}